import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router';
import { Header } from "../../common/Header";
import {
    MDBBtn,
    MDBFreeBird,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCol,
    MDBContainer,
    MDBFormInline,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBEdgeHeader,
    MDBCardTitle,
    MDBProgress,
    MDBListGroup,
    MDBListGroupItem,
    MDBBreadcrumbItem,
    MDBBreadcrumb,
    MDBBadge,
    MDBCollapse
} from "mdbreact";
import { Chart } from "../common/VulnChart"
import { useHistory } from "react-router";
import "./style.css";
import { fetchData } from '../../services/apiConfig'
import { PhaseSelector } from "./PhaseSelector";
export const Pentest = (props: any) => {
    //console.log(props)
    const [domains, setDomain] = useState([{
        "name": "Phase",
        "period": "quarter-3",
        "infoCount": 10,
        "lowCount": 10,
        "mediumCount": 10,
        "highCount": 10,
        "criticalCount": 10,
        "totalCount": 10,
        "lastSynced": "()"
    },
    {
        "name": "Phase",
        "period": "quarter-3",
        "infoCount": 10,
        "lowCount": 10,
        "mediumCount": 10,
        "highCount": 10,
        "criticalCount": 10,
        "totalCount": 10,
        "lastSynced": "September 06 2021, 02:15:08"
    }]);
    const [subDomains, setSubDomains] = useState(["subDomains"]);
    const [subDomainsfull, setSubDomainsfull] = useState(["subDomains"]);
    const [collapseID, setCollapseID] = useState("");
    const [selectedPhase, setSelectedPhase] = useState("")
    const [vulnerability, setVulnerability] = useState('all');
    const [category, setCategory] = useState('all');
    const [resolved, setResolved] = useState('all');
    const [signature, setSignature] = useState('');
    useEffect(() => {
        setSelectedPhase("")

        if (props.Account[0].indexOf("Quarter") > -1 || props.Account[0].indexOf("20") > -1) {
            fetchData('vapt', 'GET', "?quarter=" + props.Account[0].toLowerCase() + "&year=" + props.Account[1].toLowerCase()).then((res: any) => {
                setDomain(res)
            })
        }
        else {
            fetchData('vapt', 'GET', "?quarter=" + props.Kpi.metrics.vapt.defaultQuarter.toLowerCase() + "&year=" + + props.Kpi.metrics.vapt.defaultYear).then((res: any) => {
                if (res.length > 0) {
                    setDomain(res)
                }
            })
        }
    }, [props]);

    const SubDomainSwitch = (e: any) => {
        setCollapseID("")
        let acc: any;
        setSelectedPhase(e)
        setVulnerability("all");
        setCategory("all");
        setResolved("all");
        if (props.Account[0].indexOf("Quarter") > -1) {
            acc = props.Account[0]
        } else {
            acc = props.Kpi.metrics.vapt.defaultQuarter
        }
        fetchData('vapt/reports', 'GET', "?quarter=" + acc.toLowerCase() + "&phase=" + e + "&year=" + props.Account[1].toLowerCase()).then((res: any) => {
            if (res.message) {
                if (res.message.indexOf("Error") !== -1) {
                    localStorage.clear();
                    setSignature('0')
                }
            } else {
                setSubDomains(res)
                setSubDomainsfull(res)
            }
        })
    }
    const toggleCollapse = (collapseid: any) => {
        if (collapseid !== collapseID) {
            setCollapseID(collapseid)
        } else {
            setCollapseID("")
        }
    }
    const Category = async (e: any) => {
        setCategory(e.target.value)
    }
    const Vulnerability = async (e: any) => {
        setVulnerability(e.target.value);
    }
    const Resolved = async (e: any) => {
        setResolved(e.target.value);
    }
    useEffect(() => {
        let filteredSubDomain: any = new Array();
        let filterCriteria: any = new Array();
        let partialfilteredSubDomain = subDomainsfull
        const arraysomme = { "severity": vulnerability, "category": category, "isResolved": resolved !== "all" ? resolved : "all" };
        for (const key in arraysomme) {
            if ((arraysomme as any)[key] !== "all") {
                filterCriteria.push({ "key": key, "value": (arraysomme as any)[key] })
            }
        }
        if (filterCriteria.length > 0) {
            filterCriteria.map((res: any) => {
                filteredSubDomain = new Array()
                partialfilteredSubDomain.map((subD: any) => {
                    if (subD[res.key] === res.value) {
                        filteredSubDomain.push(subD)
                    }
                })
                partialfilteredSubDomain = filteredSubDomain
            })
        } else {
            filteredSubDomain = subDomainsfull
        }

        setSubDomains(filteredSubDomain)
    }, [vulnerability, category, resolved]);

    const downloadReport = () => {
        fetchData('vapt/reports/download', 'GET', "?quarter=" + props.Account[0].toLowerCase() + "&phase=" + selectedPhase + "&year=" + props.Account[1].toLowerCase()).then((res: any) => {
            if (res.message) {
                if (res.message.indexOf("Error") !== -1) {
                    localStorage.clear();
                    setSignature('0')
                }
            } else {
                window.open(res.url);
            }
        })
    }

    const exportReport = () => {
        let rowData: any = new Array;
        let csvHeader: any = ""
        let csvHeadertemp: any = ""
        subDomainsfull.map((subD: any) => {
            let reowData: any = new Array;
            (Object.keys(subD)).map((key: any) => {
                if (key !== "lastSynced" && key !== "identifier") {
                    csvHeadertemp += key
                    reowData.push(subD[key])
                    csvHeadertemp += ","
                }
            })
            csvHeader = csvHeadertemp
            csvHeadertemp = ""
            rowData.push(reowData)
        })

        let csv = csvHeader + '\n';
        rowData.forEach((row: any) => {
            csv += row.join(',');
            csv += "\n";
        });
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = props.Account + " " + selectedPhase + '.csv';
        hiddenElement.click();
    }

    const markResolved = (e: any) => {
        fetchData('vapt/reports/', 'GET', e).then((res: any) => {
            SubDomainSwitch(selectedPhase)
        })
    }

    const token = localStorage.getItem("token");
    if (signature === '0' || token === null || token === undefined || token.length < 0) {
        return <Redirect to={{
            pathname: '/login',
        }} />
    }
    return (
        <>
            <MDBContainer fluid className="pb-5" style={{ padding: "2vw" }}>
                <MDBRow>
                    <MDBCol sm="11" md="11" lg="11" style={{ padding: "0px" }}>
                        <MDBBreadcrumb >
                            <MDBBtn className="back" onClick={() => props.SwitchView('home')} color="blue-grey" style={{ border: "2px solid blue-grey", borderRadius: "5px", marginLeft: "0px !important", fontSize: "8px !important" }}>
                                <MDBIcon icon="arrow-left" style={{ fontSize: "8px" }} /> Back</MDBBtn>
                            <MDBBreadcrumbItem>dashboard</MDBBreadcrumbItem>
                            <MDBBreadcrumbItem active>pentest</MDBBreadcrumbItem>
                        </MDBBreadcrumb>
                    </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                    <MDBCol sm="11" md="11" lg="11" style={{ padding: "10px 25px" }}>
                        <MDBRow md="12" lg="12" style={{ display: "inline-flex" }}>
                            <p className='text-dark font-weight-bold' style={{ fontSize: "22px", paddingTop: "2px" }}>Vulnerability Assessment</p>
                            <span className='text-dark' style={{ fontSize: "12px", marginLeft: "10px", paddingTop: "12px" }}>Last tested on&nbsp;
                            {(domains.length > 0) ? domains[0].lastSynced : ""}</span>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <PhaseSelector Account={props.Account} AccountToggle={props.AccountToggle} Kpi={props.Kpi}/>
                <MDBRow >
                    <MDBCol sm="11" md="11" lg="11" >
                        <div style={{ maxHeight: "150vh", overflowY: "hidden", overflowX: "hidden" }}>
                            <MDBRow className="awsRow">
                                {(domains.length > 0) ?
                                    domains.map((dom: any) =>
                                        <>
                                            <MDBCol className="pentestList" sm="3" md="3" lg="3" onClick={() => SubDomainSwitch(dom.name)}>
                                                <div className="penCard" style={{ display: "inline-flex" }}>
                                                    <p className='text-dark font-weight-bold' style={{ fontSize: "15px", marginBlockEnd: "0px" }}>
                                                        Q{(props.Account[0].indexOf("Quarter") > -1) ? props.Account[0].charAt(props.Account[0].length - 1).toUpperCase() :
                                                            props.Kpi.metrics.vapt.defaultQuarter.charAt(props.Kpi.metrics.vapt.defaultQuarter.length - 1).toLowerCase()} <br />{dom.name}
                                                        <br /><span style={{ fontSize: "10px", color: "chocolate" }}>{dom.totalCount} issues</span></p>

                                                    <div style={{ float: "right" }}>
                                                        <Chart width={100} height={105} series={[dom.criticalCount, dom.highCount, dom.mediumCount, dom.lowCount]} />
                                                    </div>
                                                </div>
                                            </MDBCol>
                                            <MDBCol sm="1" md="1" lg="1" style={{ width: "20px !important" }}></MDBCol>
                                        </>
                                    )
                                    :
                                    <div><h6 style={{ fontWeight: 600, color: "red" }}>No pentests performed in this quarter.</h6></div>
                                }
                            </MDBRow>
                        </div>
                    </MDBCol>
                </MDBRow>
                <br />
                {selectedPhase.length > 0 ?
                    <MDBRow >
                        <MDBCol sm="11" md="11" lg="11" style={{ padding: "1vw 1vw 2vw 2vw", border: "1px solid #ededef", marginLeft: "18px", backgroundColor: "rgb(237, 237, 239)" }}>
                            <MDBRow style={{ display: "inline-flex", marginLeft: "5px", paddingTop: "15px", width: "inherit" }}>
                                <p className='font-weight-bold' style={{ fontSize: "25px", color: "black", textDecoration: "underline" }}>Q
                                {(props.Account[0].indexOf("Quarter") > -1) ? props.Account[0].charAt(props.Account[0].length - 1).toUpperCase() :
                                        props.Kpi.metrics.vapt.defaultQuarter.charAt(props.Kpi.metrics.vapt.defaultQuarter.length - 1).toLowerCase()} Report {selectedPhase}</p>

                                <div style={{ marginTop: "-60px", width: "inherit" }}>
                                    <MDBBtn  color="dark"
                                        className="reportDownlaod" style={{ border: "2px solid blue-grey", borderRadius: "10px", fontSize: "15px", float: "right", fontWeight: 500 }}
                                        onClick={() => {
                                            exportReport()
                                        }}
                                    >
                                        export to CSV&nbsp;
                                <MDBIcon icon="file-csv" style={{ fontSize: "15px", marginLeft: "3px" }} /></MDBBtn>

                                <MDBBtn className="float-right"
                                    color="dark"
                                    style={{ border: "2px solid blue-grey", borderRadius: "10px", fontSize: "15px" }}
                                    onClick={() => {
                                        downloadReport()
                                    }}>Download detailed report&nbsp;&nbsp;
                                    <MDBIcon fas icon="book-open" style={{ fontSize: "15px", marginLeft: "3px" }} />
                                </MDBBtn>

                                    {/* <MDBBtn color="brown"
                                        className="reportDownlaod" style={{ border: "2px solid #795548", borderRadius: "10px", fontSize: "12px", float: "right" }}
                                        onClick={() => {
                                            downloadReport()
                                        }}
                                    >
                                        Download detailed report &nbsp;
                                <MDBIcon icon="file-download" style={{ fontSize: "15px", marginLeft: "3px" }} /></MDBBtn> */}
                                </div>
                            </MDBRow>
                            <hr></hr>
                            <MDBRow style={{ fontSize: "12px", marginLeft: "10px", paddingTop: "10px" }}>
                                <div>
                                    <label style={{ fontSize: "12px", fontWeight: 500 }}>Category</label>&nbsp;
                            <br></br>
                                    <select className="browser-default custom-select" style={{ width: "250px" }}
                                        onChange={(e: any) => Category(e)} value={category}>
                                        <option value="all" >All</option>
                                        <option value="Input Validation" >Input Validation</option>
                                        <option value="Improper Access Control" >Improper Access Control</option>
                                        <option value="Violation of Secure Design Principles" >Violation of Secure Design Principles </option>
                                        <option value="Improper Authentication" >Improper Authentication</option>
                                        <option value="Information Disclosure" >Information Disclosure</option>
                                    </select>
                                </div>
                                <div style={{ marginLeft: "50px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: 500 }}>Severity</label>&nbsp;
                            <br></br>
                                    <select className="custom-select" style={{ width: "250px" }}
                                        onChange={(e: any) => Vulnerability(e)} value={vulnerability}
                                    >
                                        <option value="all" >All</option>
                                        <option value="critical" >Critical</option>
                                        <option value="high" >High</option>
                                        <option value="medium" >Medium</option>
                                        <option value="low" >Low</option>
                                    </select>
                                </div>
                                <div style={{ marginLeft: "50px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: 500 }}>Status</label>&nbsp;
                            <br></br>
                                    <select className="custom-select" style={{ width: "250px" }}
                                        onChange={(e: any) => Resolved(e)} value={resolved}
                                    >
                                        <option value="all">All</option>
                                        <option value="False">Open issues</option>
                                        <option value="Pending">Pending issues</option>
                                        <option value="True">Closed issues</option>
                                    </select>
                                </div>
                            </MDBRow>


                            <div style={{ overflowX: "hidden" }}>
                                <ul className="list-group" style={{ listStyle: "none", fontSize: "15px", marginTop: "2vw" }}>
                                    <div style={{ backgroundColor: "white" }}>
                                        {subDomains.map((subD: any, index: any) =>
                                            <div className="subDomainVulnearbility">
                                                {
                                                    subD.severity === "critical" ? <MDBBadge className="subDomainBadge" color="danger">Critical</MDBBadge> : <></>
                                                }
                                                {
                                                    subD.severity === "high" ? <MDBBadge className="subDomainBadge" color="warning">High</MDBBadge> : <></>
                                                }
                                                {
                                                    subD.severity === "medium" ? <MDBBadge className="subDomainBadge" color="primary">Medium</MDBBadge> : <></>
                                                }
                                                {
                                                    subD.severity === "low" ? <MDBBadge className="subDomainBadge" color="success">Low</MDBBadge> : <></>
                                                }
                                                <li className="list-group-item-pentest" onClick={() =>
                                                    toggleCollapse("collapse" + index++)}>
                                                        {/* {
                                                    subD.severity === "critical" ? <MDBBadge className="subDomainBadge" color="danger">Critical</MDBBadge> : <></>
                                                }
                                                {
                                                    subD.severity === "high" ? <MDBBadge className="subDomainBadge" color="warning">High</MDBBadge> : <></>
                                                }
                                                {
                                                    subD.severity === "medium" ? <MDBBadge className="subDomainBadge" color="primary">Medium</MDBBadge> : <></>
                                                }
                                                {
                                                    subD.severity === "low" ? <MDBBadge className="subDomainBadge" color="success">Low</MDBBadge> : <></>
                                                } */}
                                                        &nbsp;{subD.name}
                                                    {subD.isResolved === "True" ?
                                                        <span className="resolvedvbadge"> Resolved &nbsp;<i className={collapseID !== "" ? "fa fa-angle-right" : "fa fa-angle-down"} /></span>
                                                        : subD.isResolved === "False" ?
                                                        <span className="unresolvedvbadge"> Unresolved &nbsp; <i className={collapseID !== "" ? "fa fa-angle-right" : "fa fa-angle-down"} /></span> :
                                                        <span className="pendingvbadge"> Pending &nbsp; <i className={collapseID !== "" ? "fa fa-angle-right" : "fa fa-angle-down"} /></span> }
                                                </li>


                                                <MDBCollapse id={"collapse" + index} isOpen={collapseID}>
                                                    {subD.name ?
                                                        <div>
                                                            <MDBRow style={{ color: "gray" }} >
                                                                <div style={{ marginLeft: "4vw", fontSize: "12px" }}>
                                                                    {(Object.keys(subD)).map((key: any) => {
                                                                        if (key !== "lastSynced" && key !== "identifier" && key !== "period" && key !== "phase") {
                                                                            return <div>
                                                                                {subD[key].length > 0 ? <div style={{ fontSize: "12px", display: "flex" }}><div className="InfoKey" style={{ fontSize: "12px", width: "20vw", minWidth: "20vw" }}>{key} </div>
                                                                                    <p style={{ marginLeft: "4vw", fontSize: "12px", textDecoration: "underline", cursor: "pointer", color: "cadetblue", lineHeight: "5px" }} >{subD[key]}</p>
                                                                                </div>
                                                                                    : <></>}
                                                                            </div>
                                                                        }
                                                                    })}
                                                                </div>
                                                            </MDBRow>
                                                            {subD.isResolved === "False" ? <div>
                                                                <MDBBadge onClick={() => {
                                                                    markResolved(subD.identifier)
                                                                }} style={{ float: "right", cursor: "pointer", fontSize: "12px" }} className="subDomainBadge" value={subD.identifier}>
                                                                    Mark as resolved</MDBBadge>

                                                            </div> : <></>
                                                            }
                                                             {subD.isResolved === "Pending" ? <div>
                                                                <MDBBadge color="warning" style={{ float: "right",  fontSize: "12px" }} className="subDomainBadge" value={subD.identifier}>
                                                                    Pending Verification</MDBBadge>

                                                            </div> : <></>
                                                            }
                                                            <br /></div> : <div />
                                                    }
                                                </MDBCollapse>
                                            </div>
                                        )}
                                    </div>
                                </ul>
                            </div>
                        </MDBCol>
                    </MDBRow> : <></>}
            </MDBContainer>
        </>
    )
}